import { NavLink } from "react-router-dom";
import { useState } from "react";
import { motion } from "framer-motion";
import Humburger from "./Humburger";
import CSS from "./NavbarCSS.module.css";
import logo from "../../assets/brand/header-logo.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);

  const setTransparency = () => {
    if (window.scrollY >= 30) {
      setIsTransparent(false);
    } else {
      setIsTransparent(true);
    }
  };
  const close = () => {
    setIsOpen(false);
  };
  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  window.addEventListener("scroll", setTransparency);
  window.addEventListener("resize", close);

  return (
    <motion.header
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
      className={`${CSS.header} ${!isTransparent ? CSS.header_bg : ""} ${
        isOpen ? CSS.hum_open : ""
      }`}
    >
      <nav className={CSS.nav}>
        <NavLink
          className={CSS.logo}
          to="/"
          onClick={() => {
            close();
            scrollUp();
          }}
        >
          <img src={logo} alt="gerda's logo" />
        </NavLink>
        <ul className={`${!isOpen ? CSS.pages : CSS.hum_pages}`}>
          <li className={`${!isOpen ? CSS.page : CSS.hum_page}`}>
            <NavLink
              to="/"
              onClick={() => {
                close();
                scrollUp();
              }}
            >
              Home
            </NavLink>
          </li>
          <li className={`${!isOpen ? CSS.page : CSS.hum_page}`}>
            <NavLink
              to="how-it-works"
              onClick={() => {
                close();
                scrollUp();
              }}
            >
              How it works
            </NavLink>
          </li>
          <li className={`${!isOpen ? CSS.page : CSS.hum_page}`}>
            <NavLink
              to="about-us"
              onClick={() => {
                close();
                scrollUp();
              }}
            >
              About us
            </NavLink>
          </li>
          <li className={`${!isOpen ? CSS.call : CSS.hum_call}`}>
            <NavLink
              to="contact"
              onClick={() => {
                close();
                scrollUp();
              }}
            >
              Get Pricing
            </NavLink>
          </li>
        </ul>
        <Humburger isOpen={isOpen} setIsOpen={setIsOpen} />
      </nav>
    </motion.header>
  );
};

export default Navbar;
