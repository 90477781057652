import { motion } from "framer-motion";
import OurTeam from "../../components/our-team/OurTeam";
import CSS from "./AboutUs.module.css";
const AboutUs = () => {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      className={CSS.main}
    >
      <h1>
        <span className={CSS.blue}>Gerda </span>is your reliable medical courier
        company
      </h1>
      <section className={CSS.section}>
        <article className={CSS.left}>
          <p>
            We are the connecting link between patients looking for a successful
            pregnancy and IVF clinics that provide the most promising results.
          </p>
          <p>
            We are proud of our experience and expertise in logistics for IVF
            needs with our service covering over 50+ countries as of today.
          </p>
        </article>
        <article className={CSS.right}>
          <p>
            We love our customers and value their trust in our work, that is
            what drives us. We give hope in return.
          </p>
          <p>
            It is true to say, that the reasons behind a customer contacting us
            are complicated. We know that path. We have been there. And we are
            here to show you the way to make it better.
          </p>
          <p className={CSS.sign}>
            Here to help.
            <br />
            GERDA cryo carry.
          </p>
        </article>
      </section>
      <OurTeam />
    </motion.main>
  );
};

export default AboutUs;
