import CSS from "./OurTeamCSS.module.css";
import ihorImg from "../../assets/images/ihor-photo.png";
import hannaImg from "../../assets/images/hanna-photo.png";

const OurTeam = () => {
  return (
    <section className={CSS.our_team}>
      <figure className={CSS.ihor}>
        <img src={ihorImg} alt="ihor hevko" width="388px" height="auto" />
        <figcaption className={CSS.figcaption}>
          <span className={CSS.name}>Ihor</span> Hevko
          <span className={CSS.position}>Founder / CEO</span>
        </figcaption>
      </figure>
      <figure className={CSS.hanna}>
        <img
          src={hannaImg}
          alt="hanna ribii-sliunchenko"
          width="388px"
          height="auto"
        />
        <figcaption className={CSS.figcaption}>
          <span className={CSS.name}>Hanna</span>
          <br />
          Rybii-Sliunchenko
          <span className={CSS.position}>Head of Logistics Department</span>
        </figcaption>
      </figure>
    </section>
  );
};

export default OurTeam;
