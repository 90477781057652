import { motion, AnimatePresence } from "framer-motion";
import CSS from "./ButtonCSS.module.css";

const Button = ({ text, type, disabled, fontSize, loading }) => {
  return (
    <button
      style={{ fontSize }}
      className={`${!disabled ? CSS.button : `${CSS.button} ${CSS.disabled}`}`}
      type={type}
      disabled={disabled}
    >
      <span className={CSS.text}>{text}</span>
      <span className={CSS.circle}>
        <AnimatePresence>
          {loading ? (
            <motion.span
              key="spinner"
              className={CSS.spinner}
              animate={{ rotate: 360 }}
              transition={{ repeat: Infinity, duration: 0.6 }}
            ></motion.span>
          ) : (
            <motion.span
              key="arrow"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
              exit={{ opacity: 0 }}
            >
              &#10140;
            </motion.span>
          )}
        </AnimatePresence>
      </span>
    </button>
  );
};

export default Button;
