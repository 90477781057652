import { useState } from "react";
import { motion } from "framer-motion";
import CSS from "./SliderCSS.module.css";

const opinions = [
  {
    id: "0",
    content:
      "Ihor!!! Thanks so much to you for supporting us all the way. You really have been a great support in all ways. We will of course keep you updated. I know you love to have pictures of “your” babies! Keep up the great work ❤️",
    name: "Diana",
  },
  {
    id: "1",
    content:
      "Hello Ihor! I was told that you arrived safely at the clinic 🥳. Thank you very much for everything 🫶 !! We are very happy to have met you 🥰 We're going to have a baby 😍!!!!! This is incredible news and it’s thanks to you! Thank you so much!",
    name: "Justine",
  },
  {
    id: "2",
    content:
      "Grazie per il tuo pensiero verso di me, significa davvero molto! 😃 Sarò più che felice di tenerti aggiornato sull'esito della gravidanza. 😍 La tua professionalità e il tuo sostegno sono davvero apprezzati, ti ringrazio di cuore!",
    name: "Monica",
  },
  {
    id: "3",
    content:
      "Привіт Ігор! Хотіла ще раз подякувати вашій команді за якісну і швидку доставку! В нас все вийшло 🤞🏾 вчора було перше УЗД 🤞🏾😇🥰🥹 Успіхів, натхнення, здоровʼя для досягнення бажаного! ✊🏾🫱🏽‍🫲🏾🙌🏾 Гарного дня ! 🤗",
    name: "Марія",
  },
  {
    id: "4",
    content:
      "Thanks a lot Ihor. For all of you being professional. It's because of your professionalism. I recommended it to some friends already. I think one of them has a scheduled pickup date already in New York.",
    name: "Kiki",
  },
];

const Slider = () => {
  const [positionIndexes, setPositionIndexes] = useState([0, 1, 2, 3, 4]);

  const handleNext = () => {
    setPositionIndexes((prevIndexes) => {
      const updatedIndexes = prevIndexes.map(
        (prevIndex) => (prevIndex + 1) % 5
      );
      return updatedIndexes;
    });
  };
  const handlePrevious = () => {
    setPositionIndexes((prevIndexes) => {
      const updatedIndexes = prevIndexes.map(
        (prevIndex) => (prevIndex - 1 + 5) % 5
      );
      return updatedIndexes;
    });
  };

  const positions = ["center", "left1", "left", "right", "right1"];

  const opinionVariants = {
    center: {
      x: "0%",
      y: "0%",
      scale: 1,
      opacity: 1,
      background: "rgba(59, 175, 241, 1)",
      color: "rgba(255, 255, 255, 1)",
      zIndex: 5,
    },
    left1: {
      x: "-110%",
      y: "25%",
      scale: 0.9,
      opacity: 0.9,
      background: "rgba(255, 255, 255, 1)",
      color: "rgba(0, 0, 0, 1)",
      zIndex: 2,
    },
    left: {
      x: "-210%",
      y: "25%",
      scale: 0.8,
      opacity: 0.8,
      background: "rgba(255, 255, 255, 1)",
      color: "rgba(0, 0, 0, 1)",
      zIndex: 1,
    },
    right: {
      x: "210%",
      y: "25%",
      scale: 0.8,
      opacity: 0.8,
      background: "rgba(255, 255, 255, 1)",
      color: "rgba(0, 0, 0, 1)",
      zIndex: 1,
    },
    right1: {
      x: "110%",
      y: "25%",
      scale: 0.9,
      opacity: 0.9,
      background: "rgba(255, 255, 255, 1)",
      color: "rgba(0, 0, 0, 1)",
      zIndex: 2,
    },
  };
  return (
    <section className={CSS.container}>
      <h2 className={CSS.title}>
        Success stories
        <span className={CSS.first}>
          {" "}
          from happy
          <span className={CSS.second}> future parents</span>
        </span>
      </h2>
      <div className={CSS.slider}>
        {opinions.map((opinion) => (
          <motion.div
            className={CSS.opinion}
            key={opinion.id}
            initial="center"
            animate={positions[positionIndexes[opinion.id]]}
            variants={opinionVariants}
            transition={{ type: "spring", stiffness: 80, duration: 0.3 }}
          >
            <p className={CSS.content}>{opinion.content}</p>
            <p className={CSS.name}>{opinion.name}</p>
          </motion.div>
        ))}
        <div className={CSS.buttons}>
          <button onClick={handlePrevious} className={CSS.left_arrow}>
            <span>&#10140;</span>
          </button>
          <button onClick={handleNext} className={CSS.right_arrow}>
            <span>&#10140;</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Slider;
