import CSS from "./FooterCSS.module.css";
import logo from "../../assets/brand/footer-logo.svg";
import fbIcon from "../../assets/svg/fb-icon.svg";
import insIcon from "../../assets/svg/ins-icon.svg";
import inIcon from "../../assets/svg/in-icon.svg";
import wechatIcon from "../../assets/svg/wechat-icon.svg";
import tiktokIcon from "../../assets/svg/tiktok-icon.svg";

const Footer = () => {
  return (
    <footer className={CSS.footer}>
      <div className={CSS.links}>
        <button>
          <a
            href="https://www.facebook.com/Gerda.cryocarry/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={fbIcon} alt="facebook" />
          </a>
        </button>
        <button>
          <a
            href="https://www.instagram.com/gerda_cryocarry?igsh=MWlpNTExMzFsZDdn"
            rel="noreferrer"
            target="_blank"
          >
            <img src={insIcon} alt="instagram" />
          </a>
        </button>
        <button>
          <a
            href="https://www.linkedin.com/company/gerda-cryo-carry?trk=blended-typeahead"
            rel="noreferrer"
            target="_blank"
          >
            <img src={inIcon} alt="linkedin" />
          </a>
        </button>
        <button>
          <a
            href="https://u.wechat.com/kIrdEoFDhPBKVsS89JeBT5A"
            rel="noreferrer"
            target="_blank"
          >
            <img src={wechatIcon} alt="wechat" />
          </a>
        </button>
        <button>
          <a
            href="https://www.tiktok.com/@gerda_cryocarry?_t=8mUCW05Vpvk&_r=1"
            rel="noreferrer"
            target="_blank"
          >
            <img src={tiktokIcon} alt="tiktok" />
          </a>
        </button>
      </div>
      <p className={CSS.about}>
        Your reliable <span>medical courier</span>
      </p>
      <p className={CSS.contacts}>
        info@gerda-cryocarry.com <span>+380 93 715 32 81</span>
        <span className={CSS.address}>Lviv, Ukraine</span>
      </p>
      <div className={CSS.logo_section}>
        <img className={CSS.logo} src={logo} alt="gerda logo" />
        <p className={CSS.rights}>© Gerda. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
