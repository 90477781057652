import { motion } from "framer-motion";
import CSS from "./ContactCSS.module.css";
import ContactForm from "../../components/contact-form/ContactForm";
import ContactInfo from "../../components/contact-info/ContactInfo.js";

const Contact = () => {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      className={CSS.mainest}
    >
      <section className={CSS.main}>
        <h1>
          Let’s talk{" "}
          <span className={CSS.black}>
            and see if it’s
            <span className={CSS.next_line}> a good fit for you</span>
          </span>
        </h1>
        <section className={CSS.get_info}>
          <ContactInfo />
          <ContactForm />
        </section>
      </section>
    </motion.main>
  );
};

export default Contact;
