import { Link } from "react-router-dom";
import CSS from "./ContactSectionCSS.module.css";
import Button from "../button/Button";
import Chat from "../chat/Chat";

const ContactSection = () => {
  return (
    <section className={CSS.contact_us}>
      <Chat />
      <Link
        className={CSS.button}
        to="/contact"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <Button text="Let's talk" type="button" />
      </Link>
    </section>
  );
};

export default ContactSection;
