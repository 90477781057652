import CSS from "./StepCSS.module.css";

const Step = ({ img, title, text }) => {
  return (
    <article className={CSS.step}>
      <img
        src={img}
        alt={title}
        decoding="async"
        width="200px"
        height="233px"
      />
      <h2 className={CSS.title}>{title}</h2>
      <p className={CSS.text}>{text}</p>
    </article>
  );
};

export default Step;
