import CSS from './ChatCSS.module.css';

const Chat = () => {
    return (
        <div className={CSS.chat}>
            <p className={CSS.text_one}>Hello!</p>
            <p className={CSS.text_two}>I need IVF biomaterial<br />transportation.</p>
            <p className={CSS.our_text}>Hi! We will take care<br />of everything! </p>
        </div>
    )
}

export default Chat;
