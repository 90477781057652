import CSS from "./ContactFormCSS.module.css";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { Switch } from "antd";
import Button from "../button/Button";
import Modal from "./Modal";

const ContactForm = () => {
  const form = useRef();

  const [toggle, setToggle] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [yesOrNo, setYesOrNo] = useState("wants a 15 min. call :)");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [question, setQuestion] = useState("");

  const canSend = [name, email, phone].every(Boolean);

  const onToggle = () => {
    if (toggle) {
      setToggle(false);
      setYesOrNo("doesn't want a 15 min. call :(");
    } else {
      setToggle(true);
      setYesOrNo("wants a 15 min. call :)");
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    if (canSend) {
      emailjs
        .sendForm("service_6yq3qpi", "template_zmr5gbm", form.current, {
          publicKey: "4QXnLy0t0PmGlgE05",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            setShowModal(true);
            setName("");
            setEmail("");
            setPhone("");
            setQuestion("");
            setLoading(false);
          },
          (error) => {
            console.log("FAILED...", error.text);
            setLoading(false);
          }
        );
    }
  };

  return (
    <form className={CSS.contact_form} ref={form} onSubmit={sendEmail}>
      <div className={CSS.video_call}>
        <label
          className={toggle ? CSS.vis_label_on : CSS.vis_label_off}
          htmlFor="toggle"
        >
          I want a 15-minute video call
        </label>
        <Switch
          className={CSS.switch}
          value={toggle}
          onClick={onToggle}
          style={{ backgroundColor: toggle ? "#01B2FF" : "#D9D9D9" }}
        />
      </div>
      <input
        id="toggle"
        name="call"
        value={yesOrNo}
        readOnly
        style={{ display: "none" }}
      />
      <label className={CSS.hid_label} htmlFor="user_name">
        Name:
      </label>
      <input
        id="user_name"
        name="user_name"
        type="text"
        placeholder="Name*"
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <label className={CSS.hid_label} htmlFor="user_email">
        E-mail:
      </label>
      <input
        id="user_email"
        name="user_e-mail"
        type="email"
        placeholder="E-mail*"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <label className={CSS.hid_label} htmlFor="user_phone_number">
        Phone Number:
      </label>
      <input
        id="user_phone_number"
        name="user_phone_number"
        type="tel"
        placeholder="Phone number*"
        required
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <label className={CSS.hid_label} htmlFor="user_message">
        My question
      </label>
      <textarea
        id="user_message"
        name="user_message"
        placeholder="My question"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      ></textarea>
      <Button
        text="Send request"
        type="submit"
        disabled={!canSend}
        loading={loading}
      />
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </form>
  );
};

export default ContactForm;
