import CSS from "./ContactInfoCSS.module.css";
import phoneIcon from "../../assets/svg/phone-icon.svg";
import mailIcon from "../../assets/svg/e-mail-icon.svg";

const ContactInfo = () => {
  return (
    <section className={CSS.contact_info}>
      <p className={CSS.info}>
        Although we comply with the highest medical transportation standards,
        our aim is to get you the lowest quote possible.
        <br />
        Be sure to plan your cryo-shipping in advance to make the best out of
        it.
      </p>
      <p className={CSS.hint}>
        Fill out the contact form and we will answer all your questions in any
        form convenient for you or just call us.
      </p>
      <div className={CSS.phone}>
        <button type="button">
          <a id="phone" href="tel:+380937153281">
            <img src={phoneIcon} alt="phone" width="2rem" height="2rem" />
          </a>
        </button>
        <p>
          <label htmlFor="phone">Phone</label>
          <br />
          <a id="phone" href="tel:+380937153281">
            +380937153281
          </a>
        </p>
      </div>
      <div className={CSS.mail}>
        <button type="button">
          <a
            id="e-mail"
            href="mailto:
info@gerda-cryocarry.com"
          >
            <img src={mailIcon} alt="e-mail" width="2rem" height="2rem" />
          </a>
        </button>
        <p>
          <label htmlFor="e-mail">Email</label>
          <br />
          <a
            id="e-mail"
            href="mailto:
info@gerda-cryocarry.com"
          >
            info@gerda-cryocarry.com
          </a>
        </p>
      </div>
    </section>
  );
};

export default ContactInfo;
