import { motion } from "framer-motion";
import CSS from "./HomePageCSS.module.css";
import Marquee from "react-fast-marquee";
import logo from "../../assets/brand/logo-scrolling.svg";
import Slider from "../../components/opinions/Slider";
import ContactSection from "../../components/contact-section/ContactSection";

const HomePage = () => {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      className={CSS.main}
    >
      <section className={CSS.bg_image}>
        <h1 className={CSS.question}>
          Sending IVF biomaterials
          <br />
          for the first time?<span className={CSS.answer}>We will help!</span>
        </h1>
      </section>
      <Marquee className={CSS.marquee} autoFill speed="80">
        <h2>ivf delivery to ukraine</h2>
        <img src={logo} alt="logo" />
      </Marquee>
      <Slider />
      <ContactSection />
    </motion.main>
  );
};

export default HomePage;
