import { motion, AnimatePresence } from "framer-motion";
import CSS from "./ContactFormCSS.module.css";

const backdrop = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const modal = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: { y: "40vh", opacity: 1 },
};

const Modal = ({ showModal, setShowModal }) => {
  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          className={CSS.backdrop}
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <motion.div className={CSS.modal} variants={modal}>
            <button
              className={CSS.close_btn}
              onClick={() => setShowModal(false)}
            >
              &#10006;
            </button>
            <p>
              Success! 🚀
              <br />
              Your message is on its way. Thanks for reaching out! We'll be in
              touch soon. Explore more on our site while you wait. Have a great
              day!
            </p>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
