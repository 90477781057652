import { motion } from 'framer-motion';
import CSS from './NavbarCSS.module.css';

const Humburger = ({ isOpen, setIsOpen }) => {
    return (
        <>
        <motion.button className={CSS.humburger} animate={isOpen ? "open" : "closed"} onClick={() => setIsOpen(!isOpen)} whileHover={{scale: 1.08}}>
            <motion.span variants={{
                open: { rotate: -135, y: 8.9, scale: 1.1 },
                closed: { rotate: 0 }
            }}></motion.span>
            <motion.span variants={{
                open: { opacity: 0 },
                closed: { opacity: 1 }
            }}></motion.span>
            <motion.span variants={{
                open: { rotate: 135, y: -8.9, scale: 1.1 },
                closed: { rotate: 0 }
            }}></motion.span>
        </motion.button>
        </>
    )
}

export default Humburger;