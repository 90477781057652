import { motion } from "framer-motion";
import firstStep from "../../assets/images/first-step.png";
import secondStep from "../../assets/images/second-step.png";
import thirdStep from "../../assets/images/third-step.png";
import fourthStep from "../../assets/images/fourth-step.png";
import Step from "../../components/step/Step";
import CSS from "./HowItWorks.module.css";

const info = [
  {
    img: firstStep,
    title: "Meet and Greet",
    text: "Get in touch with our team for the best price quote and time options to make your IVF delivery safe and fast.",
  },
  {
    img: secondStep,
    title: "It’s the details",
    text: "Sign the shipping agreement and provide contacts at both clinics so we can get in touch with them and arrange the rest on your behalf, while you take your time to relax.",
  },
  {
    img: thirdStep,
    title: "Pay-as-you-go",
    text: "To initiate the process of transporting, simply provide us with a copy of your bank transfer invoice form.",
  },
  {
    img: fourthStep,
    title: "On the road",
    text: "We will dispatch your medical courier. The transportation is performed with excellent care door-to-door from both clinics. We use only top equipment with temperature data loggers, which will monitor and record temperature inside the container.",
  },
];

const HowItWorks = () => {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      className={CSS.main}
    >
      <h1>
        Find out how the whole
        <span className={CSS.new_line}>
          <span className={CSS.blue}> process</span> looks step by step
        </span>
      </h1>
      <section className={CSS.steps}>
        <div className={CSS.above}>
          <Step img={info[0].img} title={info[0].title} text={info[0].text} />
          <Step img={info[1].img} title={info[1].title} text={info[1].text} />
        </div>
        <div className={CSS.below}>
          <Step img={info[2].img} title={info[2].title} text={info[2].text} />
          <Step img={info[3].img} title={info[3].title} text={info[3].text} />
        </div>
      </section>
    </motion.main>
  );
};

export default HowItWorks;
